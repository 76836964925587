@import '../variables/_base.scss';
@import './_variables.scss';
@import '../_main-theme-components/_creatives_templates.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,500,600,700');

body {
    position: relative;
    font-size: 62.5%;
}

body header.container {
    padding: 30px 0 20px;
}

.container {
    padding: 0;
}

.container-content {
    margin-top: 20px;
    position: relative;
}

.container-content h1,
.question-title {
    font-size: 28px;
    line-height: 28px;
    color: var(--headline-color);
}

.container-content h2 {
    color: var(--headline-color);
    font-size: 16px;
    margin-bottom: 50px;
}

.container-content h1,
.container-content h2 {
    font-stretch: semi-condensed;
    text-align: left;
}

.alpha-intent .question-subtitle.align-center,
.alpha-intent .question-title.align-center,
.question-subtitle a,
.question-subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    color: var(--headline-color) !important;
}

.question-footer a,
.question-footer {
    color: var(--disclaimer-color) !important;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    & a {
        text-decoration: underline !important;
    }
}

.alpha-intent .question-subtitle.align-left,
.alpha-intent .question-title.align-left {
    text-align: center !important;
}

.offer-footer {
    text-align: left;
}

.offer-footer p,
.question-footer {
    margin-top: 25px !important;
    text-align: left;
}

h2.main-heading {
    display: none;
}

.alpha-intent > #alpha-intent-progress {
    display: none;
}

ul {
    list-style-type: none;
}

#code {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
#code pre {
    color: #000000;
    font-size: 14px;
    background-color: #eee;
    padding: 10px;
    max-width: 800px;
    white-space: pre-line;
}

.alpha-intent {
    padding: 0px 20px;
    font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
    background: #fff;
    border-radius: 6px;
    padding-bottom: 0 !important;
    color: #000;
}
.alpha-intent p a:not(.btn) {
    border-bottom: 0;
    box-shadow: none;
}
@media (min-width: 662px) {
    .alpha-intent #alpha-intent-content .offer-buttons button,
    .alpha-intent #alpha-intent-content .offer-buttons button {
        /*         margin: 5px 10px !important; */
        margin: 0 auto 10px !important;
        width: 100%;
    }
}

.alpha-intent .question-title,
.alpha-intent .offer-title {
    color: var(--headline-color);
    font-weight: 700;
    font-stretch: semi-condensed;
    line-height: 1.07;
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 16px;
    text-transform: none !important;
    text-align: center !important;
    max-width: 100%;
    text-transform: capitalize !important;
}
.alpha-intent .question-subtitle,
.alpha-intent .question-subtitle,
.alpha-intent .offer-body {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: var(--headline-color) !important;
    margin-bottom: 10px;
}
.alpha-intent .question-answers {
    margin-top: 20px;
}
.alpha-intent .offer-title {
    max-width: 100%;
    line-height: 31px;
    font-size: 24px;
    margin-bottom: 10px;
    text-transform: none !important;
}
.alpha-intent .offer-body {
    font-weight: 400;
    text-align: left;
}
.alpha-intent .offer-footer a,
.alpha-intent .offer-footer,
.alpha-intent .offer-footer label,
.alpha-intent .offer-footer p {
    color: var(--disclaimer-color) !important;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    & a {
        text-decoration: underline !important;
    }
}
.alpha-intent .offer-buttons {
    margin-top: 20px;
}
@media (min-width: 662px) {
    .alpha-intent .offer-buttons {
        display: flex;
        justify-content: center;
    }
}
.alpha-intent .offer-buttons button {
    text-align: left !important;
    padding: 12px 17px;
}
@media (min-width: 662px) {
    .alpha-intent .offer-buttons button {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
    }
}
.alpha-intent .offer-buttons button.button-no {
    background-color: #e8e8e8;
    transition: background-color 0.3s ease-in-out;
    text-transform: uppercase;
}
@media (min-width: 662px) {
    .alpha-intent .offer-buttons button.button-no {
        order: 1;
        width: auto;
    }
}
.alpha-intent .offer-buttons button.button-no:hover {
    background-color: #f2f2f2;
    transition: background-color 0.3s ease-in-out;
}
.alpha-intent .offer-buttons button.button-yes {
    text-transform: uppercase;
}
@media (min-width: 662px) {
    .alpha-intent .offer-buttons button.button-yes {
        order: 2;
        width: auto;
    }
}
.alpha-intent button {
    background-color: var(--primary-light-color);
    box-shadow: var(--button-box-shadow);
    &:hover,
    &:focus {
        filter: brightness(95%);
        box-shadow: var(--button-box-shadow-hover);
    }
    align-items: center;
    color: var(--headline-color);
    border: 0;
    height: 78px;
    width: 100%;
    font-size: 18px;
    text-align: left !important;
    padding: 31px 16px;
    box-shadow: 0px 3px 0px 0px RGB(0 0 0/20%);
    border-radius: 0.25rem;
    cursor: pointer;
    line-height: 16px;
}
@media (min-width: 662px) {
    .alpha-intent button {
        margin-left: 0px;
        margin-right: 0px;
    }
}
.alpha-intent .ai-progression-time-estimation {
    font-weight: 700;
    color: #002f4a;
    background-color: #eee;
    padding: 10px 0;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
}

.alpha-intent button.selected {
    filter: brightness(90%);
    box-shadow: var(--button-box-shadow-hover);
}
.alpha-intent .multi-select.question-answers {
    margin-top: 5px;
}
.alpha-intent .multi-select button.question-answer-button {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}
.alpha-intent .multi-select button.question-answer-button .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border: 2px solid #10314a;
    margin-right: 8px;
}
.alpha-intent .multi-select button.question-answer-button .checkmark:after {
    content: '';
    display: none;
    margin-top: -1px;
    width: 6px;
    height: 10px;
    border: solid #10314a;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.alpha-intent .multi-select button.question-answer-button.selected .checkmark:after {
    display: block;
}
.alpha-intent .multi-select button.submit-button {
    background-color: #e8e8e8;
    margin-top: 15px !important;
}
.alpha-intent .select-text {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 14px;
    color: var(--primary-gray-color);
}
.alpha-intent .question-subtitle a {
    color: #949494;
}
.content .static-content .sw-content .question-answers.yes-no-answers {
    flex-direction: column-reverse !important;
}
.alpha-intent > #alpha-intent-content .row-tel-input .button-save-phone {
    min-height: inherit !important;
    text-align: center !important;
    justify-content: center;
}

@media (min-width: 400px) {
    .content .static-content .sw-content .question-answers.yes-no-answers .question-answer-button.button-yes {
        margin-bottom: 15px;
    }
}

.sw-container.ai-sw .time-estimation {
    font-weight: 700;
    color: #002f4a;
    background-color: #eee;
    padding: 10px 0;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    height: 47px;
    opacity: 1;
    visibility: visible;
    padding: 10px 0;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    text-align: center;
    width: 100%;
}

.sw-container.ai-sw {
    padding: 30px !important;
}

@media (min-width: 662px) {
    .alpha-intent #alpha-intent-content .offer-buttons button {
        margin: 0 auto 10px !important;
    }

    .content .static-content .sw-content .question-answers.yes-no-answers {
        flex-direction: row !important;
    }

    .alpha-intent .offer-buttons {
        flex-direction: column-reverse;
    }
}

@media (min-width: 768px) {
    .alpha-intent .question-title,
    .alpha-intent .offer-title {
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
        font-family: 'Barlow Semi Condensed';
        font-stretch: semi-condensed;
        color: var(--headline-color) !important;
    }
}

@import url('https://fonts.googleapis.com/css?family=Roboto:500&display=swap');

.row {
    position: relative;
}

.down-arrow {
    max-width: 25px;
    margin: 40px auto 60px;
}

/* arrow */
.down-arrow {
    display: flex;
    justify-content: center;
    width: 25px;
}

.back-btn {
    position: absolute;
    left: 20px;
    top: -50px;
    z-index: 1000;
}

.date-input input:focus,
.date-input input[type='email']:focus,
.date-input input[type='number']:focus,
.date-input input[type='password']:focus,
.date-input input[type='search']:focus,
.date-input input[type='tel']:focus,
.date-input input[type='text']:focus,
.date-input input[type='url']:focus,
.date-input textarea:focus,
select:focus {
    border: 0;
    outline: 0;
}

input,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
    box-shadow: none !important;
}

.step.show {
    position: relative;
}

label {
    color: var(--headline-color);
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
}
input,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
    background-color: #ffffff;
    border: solid 1px #c0c0c0;
    border-radius: 3px;
    box-shadow: inset 0 2px 0 0 rgba(200, 200, 200, 0.5);
    color: var(--headline-color);
    font-family: Roboto;
    font-weight: bold;
    height: 45px;
    padding-left: 20px;
    position: relative;
    width: 100%;
}

button.back {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    height: auto;
    letter-spacing: 0;
    line-height: 1;
    padding: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

.is-invalid input {
    box-shadow: inset 0 2px 0 0 rgba(200, 200, 200, 0.5);
    border: solid 1px #c0c0c0;
    background-color: #ffe8e8;
}

button[type='submit'] {
    height: 45px;
    border-radius: 3px;
    box-shadow: inset 0 -2px 0 0 rgba(200, 200, 200, 0.5);
    background-color: #ededed;
    width: 100%;
    outline: none;
    border: none;
    color: #acacad;
    font-size: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

button[type='submit'] svg {
    position: absolute;
    right: 20px;
}

footer {
    padding-top: 20px;
}

footer ul {
    margin: 0 25px;
}

footer ul li a {
    color: var(--headline-color);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500 !important;
    line-height: 1.92;
}

footer .copyright {
    color: #949494;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    line-height: 2.3;
}

@media (min-width: 360px) {
    #fullname__description {
        font-size: 12.5px;
        padding: 0 20px;
    }
}
@media (min-width: 375px) {
    body footer.container {
        padding-bottom: 10px;
        padding: 0 20px;
    }
}

@media (min-width: 500px) {
    .back-btn {
        left: 40px;
    }
}

@media (min-width: 640px) {
    html {
        overflow-x: hidden;
    }
    .row,
    main,
    #vue-app {
        height: initial;
    }

    .back-btn {
        left: 40px;
        top: -120px;
    }

    .container-content .row {
        justify-content: center;
        align-items: center;
    }

    .container-content h1 span {
        display: inline;
    }

    .container-content {
        width: 100%;
        margin: 50px auto 0;
    }

    .column {
        position: relative;
    }

    .row .column {
        margin-bottom: inherit;
        padding: 0;
    }

    form {
        margin-bottom: 0;
    }

    #fullname__description {
        display: none;
    }

    .down-arrow {
        display: none;
    }
}

@media (min-width: 768px) {
    .back-btn {
        height: 24px;
        width: 24px;
        left: 40px;
        top: -140px;
    }
    .container-content {
        width: 100%;
    }

    .container-content h1 {
        font-size: 34px;
    }

    .container-content h1 span {
        display: block;
    }

    .container-content .column:nth-child(2) {
        max-width: 300px;
    }

    button[type='submit'] {
        height: 50px;
        padding-left: 15px;
    }
}

@media (min-width: 960px) {
    .back-btn {
        top: -140px;
    }

    button.button {
        line-height: 28px;
    }
}

.submit-button,
.button-submit {
    max-width: none;
    color: var(--color-white);
    border: none;
    background-color: var(--primary-light-color);
    box-shadow: var(--button-box-shadow);
    transition: var(--button-transition);
    text-align: left;
    font-size: 16px;

    &:hover,
    &:focus,
    &:active {
        filter: brightness(110%);
        box-shadow: var(--button-box-shadow-hover);
    }

    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active,
    &:disabled {
        filter: brightness(100%);
        opacity: 0.5 !important;
        box-shadow: var(--button-box-shadow);
    }
}
/* /custom question title and tcpa-above css*/

header img {
    max-width: 130px;
}

@media (min-width: 768px) {
    header img {
        max-width: 200px;
    }
}

footer .footer-text {
    font-size: 11px;
    max-width: 500px;
    text-align: center;
    margin: 10px auto 0;
    padding: 0 20px;
}

/* FOOTER */
footer {
    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-top: 0;
        font-size: 16px;
        line-height: 18px;
    }
    & ul li {
        margin-bottom: 10px;
        padding-right: 0px;
        margin-right: 18px;
    }
}
footer ul li a {
    color: var(--sa-gray);
    transition: none;
}
footer ul li a:hover {
    color: var(--sa-gray);
    filter: brightness(0.3);
}
footer .copyright {
    color: #949494;
    transition: none;
    margin-bottom: 16px !important;
    letter-spacing: 0.16px;
}
@media (min-width: $breakpoint-sm) {
    footer ul li a {
        font-size: 12px;
    }
    footer .copyright {
        font-size: 10px;
        color: #949494;
        margin-bottom: 10px !important;
    }
    footer {
        & li {
            margin-bottom: 10px;
        }
    }
}

.wrapper footer.container {
    padding: 16px !important;
    max-width: 100%;
}

@media (min-width: $breakpoint-sm) {
    .wrapper footer.container {
        padding: 32px !important;
    }
}

.button,
button,
dd,
dt,
li {
    margin-bottom: 10px;
}
/* ./ FOOTER */

/* COMPLIANCE */
#ct-compliance {
    font-size: 12px;
    top: -16px;
    line-height: 12px;
}
/* ./ COMPLIANCE */

/* SKIP BUTTONS */
a#skip-link,
button.skip-button.skip-button-text {
    all: unset; // reset all component styles

    cursor: pointer;
    width: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid RGB(212 212 216);
    border-radius: 500px;
    padding: 12px 4px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 148px;
    display: block;
    text-decoration: underline;
    text-align: center;
    color: #737475;
    box-sizing: initial !important;
}

button.skip-button.skip-button-text {
    padding: 12px 64px;
    margin-top: 48px;
    margin-bottom: 0px;
    width: auto;
}

[data-object-creative-type='template_data'] a#skip-link {
    margin-top: 48px;
    margin-bottom: 48px;
}
/* ./ SKIP BUTTONS */
/* QUESTIONS */
.alpha-intent > #alpha-intent-content > [data-object-type='question'] {
    max-width: var(--max-width-container-base);
    margin: auto;
}
/* ./ QUESTIONS */

/* OFFERS */
.alpha-intent > #alpha-intent-content > [data-object-type='offer'] {
    margin-top: 16px;
}

.alpha-intent > #alpha-intent-content > [data-object-type='multiple_offers'],
.alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='html'],
.alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='multistep'] {
    position: relative;
    border: 1px solid RGB(212 212 216);
    padding: 28px 16px;
    margin-top: 16px;

    & label.question-footer {
        & a {
            text-decoration: underline !important;
        }
    }
    & label.question-footer a,
    label.question-footer p {
        color: var(--disclaimer-color);
        margin: 0 auto;
        display: block;
        font-family: 'Barlow Semi Condensed';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
    }

    .multi-select button.question-answer-button {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.alpha-intent > #alpha-intent-content > [data-object-type='multiple_offers'] {
    padding: 28px 0;

    & #ct-creatives-content {
        padding: 0 16px;
    }
}

.alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='html'] {
    padding: 8px;
}

.alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='template_data'] {
    position: relative;
    border: 1px solid RGB(212 212 216);
    margin: auto;
    max-width: 800px;
    padding: 0;
    margin-top: 16px;
}

.alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='soiyesno'] {
    position: relative;
    border: 1px solid RGB(212 212 216);
    margin: auto;
    max-width: 800px;
    padding: 0;
    margin-top: 16px;
}

@media (min-width: $breakpoint-sm) {
    .alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='html'] {
        margin: auto;
        max-width: 980px;
        padding: 28px 16px;
        margin-top: 16px;
    }

    .alpha-intent > #alpha-intent-content > [data-object-type='offer'][data-object-creative-type='multistep'] {
        margin: auto;
        max-width: 800px;
        padding: 48px 80px;
        margin-top: 16px;
    }
}
/* ./ OFFERS */

[ct-template='template-4'] {
    padding-left: 0;
    padding-right: 0;
}

/* hide SPONSORED compliance text */
#ct-creatives-content #ct-compliance {
    display: none;
}
/* hide CREATIVES CONTENT BORDER compliance text */
#ct-creatives-content {
    border: none;
}

/* FIX CREATIVE HTMLS TO LOOK LIKE SINGLE SELECT QUESTIONS TIL WE MOVE TO TEMPLATES */
.alpha-intent > #alpha-intent-content #lo2020 {
    padding: 16px;
    max-width: var(--max-width-container-base);
}

@media (min-width: $breakpoint-sm) {
    .alpha-intent > #alpha-intent-content #lo2020 {
        padding: 16px 0;
    }
}

.alpha-intent > #alpha-intent-content #lo2020-e-headline,
.alpha-intent > #alpha-intent-content #lo2020-a-headline {
    color: var(--headline-color);
    font-size: 28px;
    line-height: 28px;
    text-transform: none;
    margin-bottom: 20px;
    padding: 0;
}

.alpha-intent > #alpha-intent-content #lo2020-e-subline,
.alpha-intent > #alpha-intent-content #lo2020-a-subline {
    color: var(--headline-color);
    max-width: none;
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    padding: 0;
}

.alpha-intent > #alpha-intent-content #lo2020-e-disclaimer a,
.alpha-intent > #alpha-intent-content #lo2020-a-disclaimer a,
.alpha-intent > #alpha-intent-content #lo2020-e-disclaimer,
.alpha-intent > #alpha-intent-content #lo2020-a-disclaimer {
    max-width: var(--max-width-container-base);
    color: var(--disclaimer-color) !important;
    margin: 0 auto;
    display: block;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    padding: 0;
    margin-top: 35px;

    & a {
        font-size: 12px;
        text-decoration: underline !important;
        display: inline-block !important;
    }
}

.alpha-intent > #alpha-intent-content #lo2020-e-logo {
    padding: 0;
    margin: 0 auto;
}

.alpha-intent > #alpha-intent-content #lo2020-e-disclaimer-2 a,
.alpha-intent > #alpha-intent-content #lo2020-a-disclaimer-2 a,
.alpha-intent > #alpha-intent-content #lo2020-e-disclaimer-2,
.alpha-intent > #alpha-intent-content #lo2020-a-disclaimer-2 {
    color: var(--disclaimer-color) !important;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    padding: 0;
    & a {
        text-decoration: underline !important;
    }
}

.alpha-intent > #alpha-intent-content .lo2020-e-button,
.alpha-intent > #alpha-intent-content .lo2020-a-button {
    text-align: left !important;
    height: 78px;
    margin: 0 0 10px 0;
    width: 100%;
    max-width: var(--max-width-container-base);
    border: none;
    background-color: var(--primary-light-color);
    box-shadow: var(--button-box-shadow);
    transition: var(--button-transition);
    padding: 27px 17px;
    border-radius: var(--button-border-radius);
    font-weight: bold;
    white-space: normal;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--headline-color);

    &:hover,
    &:focus {
        filter: brightness(95%);
        box-shadow: var(--button-box-shadow-hover);
    }
}

.alpha-intent > #alpha-intent-content #lo2020 .lo2020-a-button:first-of-type,
.alpha-intent > #alpha-intent-content #lo2020 .lo2020-e-button:first-of-type {
    margin-top: 20px;
}
/* ./ FIX CREATIVE HTMLS TO LOOK LIKE SINGLE SELECT QUESTIONS TIL WE MOVE TO TEMPLATES */

.alpha-intent > #alpha-intent-content .row-tel-input input {
    margin-right: 16px;
    max-width: 200px;
    margin-bottom: 0;
    height: 63px;
}
