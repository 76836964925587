/**
      .ct-creative-path-theme class is only used by compliance creatives (template-6, template-19).

      compliance creatives: creatives that MUST LOOK EXACTLY like forms and questions, but with 'sponsored' text indication.

      IMPORTANT: besides compliance creatives, for better scale and maintenance, creative templates should have its own independent style.
    **/
.ct-creative-path-theme {
    /** 
            MOBILE FIRST - We are handling desktop breakpoint by overriding the css tokens in _variables.scss
        **/
    font-family: var(--offer-font-family);

    [ct-creatives-content] {
        border: var(--offer-content-border);
    }

    a[ct-creative] {
        text-decoration: none;
        color: inherit;
    }

    /* offerwall headline */
    .ct-headline {
        & p, // deprecated TD: fix bug plugin jornaya rendering
        .ct-headline__text div, // support quill rich-text
        .ct-headline__text {
            font-family: var(--offer-headline-font-family);
            color: var(--offer-headline-color);
            font-size: var(--offer-headline-font-size);
            max-width: var(--offer-headline-max-width);
            letter-spacing: var(--offer-headline-letter-spacing);
            line-height: var(--offer-headline-line-height);
            font-weight: var(--offer-headline-font-weight);
            text-align: var(--offer-headline-text-align);
            margin: var(--offer-headline-margin);
        }
    }

    /* offerwall subheadline */
    .ct-subheadline {
        & p, // deprecated TD: fix bug plugin jornaya rendering
        .ct-subheadline__text div, // support quill rich-text
        .ct-subheadline__text {
            font-family: var(--offer-subheadline-font-family);
            color: var(--offer-subheadline-color);
            font-size: var(--offer-subheadline-font-size);
            max-width: var(--offer-subheadline-max-width);
            letter-spacing: var(--offer-subheadline-letter-spacing);
            line-height: var(--offer-subheadline-line-height);
            font-weight: var(--offer-subheadline-font-weight);
            text-align: var(--offer-subheadline-text-align);
            text-align: var(--offer-subheadline-text-align);
            margin: var(--offer-subheadline-margin);
        }
    }

    /* creative/ad headline */
    .ct-creative-headline {
        /* support quill rich-text */
        .ct-creative-headline__text div,
        .ct-creative-headline__text {
            font-family: var(--offer-creative-headline-font-family);
            color: var(--offer-creative-headline-color);
            font-size: var(--offer-creative-headline-font-size);
            max-width: var(--offer-creative-headline-max-width);
            letter-spacing: var(--offer-creative-headline-letter-spacing);
            line-height: var(--offer-creative-headline-line-height);
            font-weight: var(--offer-creative-headline-font-weight);
            text-align: var(--offer-creative-headline-text-align);
            margin: var(--offer-creative-headline-margin);
        }
    }

    /* creative/ad subheadline */
    .ct-creative-subheadline {
        /* support quill rich-text */
        .ct-creative-subheadline__text div,
        .ct-creative-subheadline__text {
            font-family: var(--offer-creative-subheadline-font-family);
            color: var(--offer-creative-subheadline-color);
            font-size: var(--offer-creative-subheadline-font-size);
            max-width: var(--offer-creative-subheadline-max-width);
            letter-spacing: var(--offer-creative-subheadline-letter-spacing);
            line-height: var(--offer-creative-subheadline-line-height);
            font-weight: var(--offer-creative-subheadline-font-weight);
            text-align: var(--offer-creative-subheadline-text-align);
            text-align: var(--offer-creative-subheadline-text-align);
            margin: var(--offer-creative-subheadline-margin);
        }
    }

    .ct-disclaimer {
        & p, // support jornaya rendering
        a,
        .ct-disclaimer__link,
        .ct-disclaimer__text div, // support quill rich-text
        .ct-disclaimer__text {
            font-family: var(--offer-disclaimer-font-family);
            color: var(--offer-disclaimer-color) !important;
            font-size: var(--offer-disclaimer-font-size);
            text-align: var(--offer-disclaimer-text-align);
            max-width: var(--offer-disclaimer-max-width);
            letter-spacing: var(--offer-disclaimer-letter-spacing);
            line-height: var(--offer-disclaimer-line-height);
        }

        & a,
        .ct-disclaimer__link {
            text-decoration: var(--offer-disclaimer-link-text-decoration) !important;
        }
    }

    .ct-creative-actions {
        display: var(--offer-button-container-display);
        flex-direction: var(--offer-button-container-flex-direction);
        justify-content: var(--offer-button-container-justify-content);
        align-items: var(--offer-button-container-align-items);
        max-width: var(--offer-button-container-max-width);
        margin: var(--offer-button-container-margin);

        & .ct-creative-actions__wrapper-cta {
            width: 100%;
        }

        & .ct-creative-actions__yes-wrapper,
        .ct-creative-actions__no-wrapper {
            width: 100%;
        }

        & .ct-creative-actions__cta {
            justify-content: var(--offer-button-justify-content);
            color: var(--offer-button-color);
            background-color: var(--offer-button-background-color);
            padding: var(--offer-button-padding);
            margin-bottom: var(--offer-button-margin-bottom);
            max-width: var(--offer-button-max-width);
            height: var(--offer-button-height);
            border: var(--offer-button-border);
            border-bottom: var(--offer-button-border-bottom);
            border-radius: var(--offer-button-border-radius);
            box-shadow: var(--offer-button-box-shadow);
            transition: var(--offer-button-transition);
            font-size: var(--offer-button-font-size);
            font-weight: var(--offer-button-font-weight);
            text-align: var(--offer-button-text-align);
            text-transform: var(--offer-button-text-transform);
            white-space: var(--offer-button-white-space);
            cursor: var(--offer-button-cursor);

            & .ct-creative-actions__cta-text,
            slot,
            span {
                font-family: var(--offer-button-font-family);
                font-size: var(--offer-button-font-size);
                font-weight: var(--offer-button-font-weight);
                text-align: var(--offer-button-text-align);
                text-transform: var(--offer-button-text-transform);
                white-space: var(--offer-button-white-space);
            }

            &:hover,
            &:focus {
                filter: var(--offer-button-filter);
                box-shadow: var(--offer-button-box-shadow-hover);
                background-color: var(--offer-button-background-color-hover);
            }
        }
    }
}

.ai-container {
    .ai-content--offer {
        /**
            MULTI-OFFERS multi-select creatives - similar to multi-select questions
        
            It applies this style to any creative cta that has a hidden checkbox so the user
            can check multiple creatives, thus, submit multiple taken/clicked events
          **/
        .ai-checkbox--offer:not(:checked) ~ div {
            [ai-cta],
            .ai-cta {
                filter: var(--offer-button-filter-not-checked);
                box-shadow: var(--offer-button-box-shadow-not-checked);
                background-color: var(--offer-button-background-color-not-checked);
            }
        }
        .lp-main-ribbon {
            .lp-main-ribbon-container {
                background-color: var(--offer-creative-ribbon-background);
                border-color: var(--offer-creative-ribbon-border-color);
            }
        }

        .lp-creative-featured {
            box-shadow: var(--offer-creative-featured-box-shadow);

            .lp-ribbon-extra {
                display: var(--offer-creative-ribbon-extra-display);
            }

            .ai-cta {
                background-color: var(--offer-creative-featured-button-background-color);
            }
        }
        .lp-creative:not(.lp-creative-featured) {
            .lp-creative-content-text {
                display: var(--offer-creative-non-featured-text-display);
            }
            .ai-cta {
                background-color: var(--offer-creative-button-background-color);
            }
        }
    }
}
