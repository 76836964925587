//----  TOKENS  ----//

:root {
    /** Palette Colors **/
    // neutral
    --color-inherit: inherit;
    --color-current: currentColor;
    --color-transparent: transparent;
    --color-black: rgb(0 0 0);
    --color-white: rgb(255 255 255);
    // primary
    --color-gray-300: rgb(228, 230, 232);
    --color-gray-500: rgb(113, 113, 122);
    // complementary
    --color-blue-900: rgb(0, 48, 73);

    /** 
        Main Colors - https://www.color-meanings.com/primary-secondary-tertiary-colors/
        Monochromatic (1-color) - https://share.getcloudapp.com/7KuXBgyx - https://paletton.com
    **/
    --primary-gray-color: var(--color-gray-500);

    --primary-color: var(--color-gray-500);
    --primary-lighter-color: var(--color-gray-100);
    --primary-light-color: var(--color-gray-300);
    --primary-dark-color: var(--color-gray-700);
    --primary-darker-color: var(--color-gray-900);

    --complimentary-color: var(--color-blue-500);
    --complimentary-lighter-color: var(--color-blue-100);
    --complimentary-light-color: var(--color-blue-300);
    --complimentary-dark-color: var(--color-blue-700);
    --complimentary-darker-color: var(--color-blue-900);
    /**
        Layout - https://tailwindcss.com/docs/aspect-ratio 
        examples: aspect ratio; container; columns; break after; break before; break inside; box decoration break; box sizing; display; floats; clear; isolation; object fit; object position; overflow; overscroll behavior; position; top / right / bottom / left; visibility; z-index
    **/
    /**
        Flexbox & Grid - https://tailwindcss.com/docs/flex-basis
        examples: flex basis; flex direction; flex wrap; flex; flex grow; flex shrink; order; grid template columns; grid column start / end; grid template rows; grid row start / end; grid auto flow; grid auto columns; grid auto rows; gap; justify content; justify items; justify self; align content; align items; align self; place content; place items; place self
    **/
    /** 
        Spacing - https://tailwindcss.com/docs/padding
        examples: padding; margin; space between
    **/
    /** 
        Sizing - https://tailwindcss.com/docs/width
        examples: width; min-width; max-width; height; min-height; max-height
    **/
    --width-container-base: auto;
    --max-width-container-base: 640px;

    /** 
        Typography - https://tailwindcss.com/docs/font-family 
        examples: typography; font family; font size; font smoothing; font style; font weight; font variant numeric; letter spacing; line clamp; line height; list style image; list style position; list style type; text align; text color; text decoration; text decoration color; text decoration style; text decoration thickness; text underline offset; text transform; text overflow; text indent; vertical align; whitespace; word break; hyphens; content
    **/
    --font-family-base: 'Barlow Semi Condensed';

    --font-size-base: 16px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-lg: 18px;
    --font-size-xl: 20px;
    --font-size-2xl: 24px;
    --font-size-3xl: 28px;
    --font-size-4xl: 36px;

    /**
        Borders - https://tailwindcss.com/docs/border-radius
        examples: border radius; border width; border color; border style; divide width; divide color; divide style; outline width; outline color; outline style; outline offset; ring width; ring color; ring offset width; ring offset color
    **/
    --border-radius-base: 4px;

    /**
        Effects - https://tailwindcss.com/docs/border-radius
        examples: box shadow; box shadow color; opacity; mix blend mode; background blend mode
    **/

    /**
        Filters - https://tailwindcss.com/docs/blur
        examples: blur; brightness; contrast; drop shadow; grayscale; hue rotate; invert; saturate; sepia; backdrop blur; backdrop brightness; backdrop contrast; backdrop grayscale; backdrop hue rotate; backdrop invert; backdrop opacity; backdrop saturate; backdrop sepia
    **/

    /**
        Transitions & Animation - https://tailwindcss.com/docs/transition-property
        examples: transition property; transition duration; transition timing function; transition delay; animation
    **/
    --transition-base: 200ms;

    /**
        Transforms - https://tailwindcss.com/docs/scale
        examples: scale; rotate; translate; skew; transform origin
    **/

    /**
        Headlines
    **/
    --headline-font-family: var(--font-family-base);
    --headline-color: var(--complimentary-darker-color);
    --headline-font-size: var(--font-size-3xl);
    --headline-max-width: var(--max-width-container-base);
    --headline-letter-spacing: var(--letter-spacing-normal);
    --headline-line-height: var(--line-height-lg);
    --headline-font-weight: var(--font-weight-bold);

    /**
        Subheadlines
    **/
    --subheadline-font-family: var(--font-family-base);
    --subheadline-color: var(--complimentary-darker-color);
    --subheadline-font-size: var(--font-size-base);
    --subheadline-max-width: var(--max-width-container-base);
    --subheadline-letter-spacing: var(--letter-spacing-normal);
    --subheadline-line-height: var(--line-height-xs);
    --subheadline-font-weight: var(--font-weight-medium);

    /**
        Help Text
    **/
    --help-text-font-family: var(--disclaimer-font-family);
    --help-text-color: var(--disclaimer-color);
    --help-text-font-size: var(--disclaimer-font-size);
    --help-text-text-align: left;
    --help-text-max-width: var(--disclaimer-max-width);
    --help-text-letter-spacing: var(--disclaimer-letter-spacing);
    --help-text-line-height: var(--disclaimer-line-height);
    --help-text-link-text-decoration: underline;

    /**
        Disclaimer
    **/
    --disclaimer-font-family: var(--font-family-base);
    --disclaimer-color: var(--complimentary-darker-color);
    --disclaimer-font-size: var(--font-size-xs);
    --disclaimer-text-align: left;
    --disclaimer-max-width: var(--max-width-container-base);
    --disclaimer-letter-spacing: var(--letter-spacing-wide);
    --disclaimer-line-height: var(--line-height-xs);

    --disclaimer-link-text-decoration: underline;

    /**
        Buttons
    **/
    --button-justify-content: left;
    --button-color: var(--complimentary-darker-color);
    --button-background-color: var(--primary-light-color);
    --button-background-color-hover: var(--primary-light-color);
    --button-padding: 0 16px;
    --button-margin-bottom: 10px;
    --button-max-width: var(--max-width-container-base);
    --button-height: 78px;
    --button-font-size: var(--font-size-lg);
    --button-font-weight: var(--font-weight-bold);
    --button-text-align: left;
    --button-text-transform: capitalize;
    --button-white-space: normal;
    --button-border: none;
    --button-border-bottom: none;
    --button-border-radius: var(--border-radius-base);
    --button-box-shadow: 0px 3px 0px 0px RGB(0 0 0/20%);
    --button-box-shadow-hover: 0px 3px 0px 0px RGB(0 0 0/30%);
    --button-transition: box-shadow var(--transition-base);
    --button-filter: brightness(95%);

    /**
        Offers
    **/
    --offer-font-family: var(--font-family-base);
    --offer-width: var(--width-container-base);

    --offer-headline-font-family: var(--headline-font-family);
    --offer-headline-color: var(--headline-color);
    --offer-headline-font-size: var(--headline-font-size);
    --offer-headline-max-width: var(--headline-max-width);
    --offer-headline-letter-spacing: var(--headline-letter-spacing);
    --offer-headline-line-height: var(--headline-line-height);
    --offer-headline-font-weight: var(--headline-font-weight);

    --offer-subheadline-font-family: var(--subheadline-font-family);
    --offer-subheadline-color: var(--subheadline-color);
    --offer-subheadline-font-size: var(--subheadline-font-size);
    --offer-subheadline-max-width: var(--subheadline-max-width);
    --offer-subheadline-letter-spacing: var(--subheadline-letter-spacing);
    --offer-subheadline-line-height: var(--subheadline-line-height);
    --offer-subheadline-font-weight: var(--subheadline-font-weight);

    --offer-disclaimer-font-family: var(--disclaimer-font-family);
    --offer-disclaimer-color: var(--disclaimer-color);
    --offer-disclaimer-font-size: var(--disclaimer-font-size);
    --offer-disclaimer-text-align: var(--disclaimer-text-align);
    --offer-disclaimer-max-width: var(--disclaimer-max-width);
    --offer-disclaimer-letter-spacing: var(--disclaimer-letter-spacing);
    --offer-disclaimer-line-height: var(--disclaimer-line-height);

    --offer-disclaimer-link-text-decoration: var(--disclaimer-link-text-decoration);

    --offer-button-justify-content: var(--button-justify-content);
    --offer-button-color: var(--button-color);
    --offer-button-background-color: var(--button-background-color);
    --offer-button-padding: var(--button-padding);
    --offer-button-margin-bottom: var(--button-margin-bottom);
    --offer-button-width: var(--button-width);
    --offer-button-max-width: var(--button-max-width);
    --offer-button-height: var(--button-height);
    --offer-button-font-size: var(--button-font-size);
    --offer-button-font-weight: var(--button-font-weight);
    --offer-button-text-align: var(--button-text-align);
    --offer-button-text-transform: var(--button-text-transform);
    --offer-button-white-space: var(--button-white-space);
    --offer-button-border: var(--button-border);
    --offer-button-border-bottom: var(--button-border-bottom);
    --offer-button-border-radius: var(--button-border-radius);
    --offer-button-box-shadow: var(--button-box-shadow);
    --offer-button-transition: var(--button-transition);
    --offer-button-filter: var(--button-filter);

    --offer-button-box-shadow-hover: var(--button-box-shadow-hover);
    --offer-button-background-color-hover: var(--button-background-color-hover);
    --offer-button-filter-not-checked: brightness(95%);
    --offer-button-box-shadow-not-checked: inset 0px 0px 0px 3px var(--color-gray-200);
    --offer-button-background-color-not-checked: var(--color-white);

    --offer-creative-ribbon-background: rgba(39, 39, 42, 1);
    --offer-creative-ribbon-border-color: rgba(96, 165, 250, 1);
    --offer-creative-ribbon-extra-display: block;
    --offer-creative-featured-box-shadow: 0px 0px 0px 2px #023e94, 0px 4px 0px 2px #023e94, 0px 3px 10px 5px #023e94;
    --offer-creative-featured-button-background-color: rgba(249, 115, 22, 1);
    --offer-creative-button-background-color: rgba(249, 115, 22, 1);
    --offer-creative-non-featured-text-display: block;
}

//----  DESKTOP override TOKENS  ----//

@media (min-width: $breakpoint-sm) {
    :root {
        /**
        OFFER
        **/
        --offer-width: 50rem;
    }
}
