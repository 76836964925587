//----  BASE TOKENS  ----//

:root {
    /** Palette Colors **/
    --color-inherit: inherit;
    --color-current: currentColor;
    --color-transparent: transparent;
    --color-black: rgb(0 0 0);
    --color-white: rgb(255 255 255);
    --color-gray-50: rgb(249 250 251);
    --color-gray-100: rgb(243 244 246);
    --color-gray-200: rgb(229 231 235);
    --color-gray-300: rgb(209 213 219);
    --color-gray-400: rgb(156 163 175);
    --color-gray-500: rgb(107 114 128);
    --color-gray-600: rgb(75 85 99);
    --color-gray-700: rgb(55 65 81);
    --color-gray-800: rgb(31 41 55);
    --color-gray-900: rgb(17 24 39);
    --color-gray-950: rgb(3 7 18);
    --color-red-100: rgb(254 226 226);
    --color-red-300: rgb(252 165 165);
    --color-red-500: rgb(239 68 68);
    --color-red-700: rgb(185 28 28);
    --color-red-900: rgb(127 29 29);
    --color-orange-100: rgb(255 237 213);
    --color-orange-300: rgb(253 186 116);
    --color-orange-500: rgb(249 115 22);
    --color-orange-700: rgb(194 65 12);
    --color-orange-900: rgb(124 45 18);
    --color-amber-100: rgb(254 243 199);
    --color-amber-300: rgb(252 211 77);
    --color-amber-500: rgb(245 158 11);
    --color-amber-700: rgb(180 83 9);
    --color-amber-900: rgb(120 53 15);
    --color-yellow-100: rgb(254 249 195);
    --color-yellow-300: rgb(253 224 71);
    --color-yellow-500: rgb(234 179 8);
    --color-yellow-700: rgb(161 98 7);
    --color-yellow-900: rgb(113 63 18);
    --color-green-100: rgb(220 252 231);
    --color-green-300: rgb(134 239 172);
    --color-green-500: rgb(34 197 94);
    --color-green-700: rgb(21 128 61);
    --color-green-900: rgb(20 83 45);
    --color-teal-100: rgb(204 251 241);
    --color-teal-300: rgb(94 234 212);
    --color-teal-500: rgb(20 184 166);
    --color-teal-700: rgb(15 118 110);
    --color-teal-900: rgb(19 78 74);
    --color-blue-100: rgb(219 234 254);
    --color-blue-300: rgb(147 197 253);
    --color-blue-500: rgb(59 130 246);
    --color-blue-700: rgb(29 78 216);
    --color-blue-900: rgb(30 58 138);
    --color-indigo-100: rgb(224 231 255);
    --color-indigo-300: rgb(165 180 252);
    --color-indigo-500: rgb(99 102 241);
    --color-indigo-700: rgb(67 56 202);
    --color-indigo-900: rgb(49 46 129);
    --color-purple-100: rgb(243 232 255);
    --color-purple-300: rgb(216 180 254);
    --color-purple-500: rgb(168 85 247);
    --color-purple-700: rgb(126 34 206);
    --color-purple-900: rgb(88 28 135);
    --color-rose-100: rgb(255 228 230);
    --color-rose-300: rgb(253 164 175);
    --color-rose-500: rgb(244 63 94);
    --color-rose-700: rgb(190 18 60);
    --color-rose-900: rgb(136 19 55);

    /** 
        Typography - https://tailwindcss.com/docs/font-family 
        examples: typography; font family; font size; font smoothing; font style; font weight; font variant numeric; letter spacing; line clamp; line height; list style image; list style position; list style type; text align; text color; text decoration; text decoration color; text decoration style; text decoration thickness; text underline offset; text transform; text overflow; text indent; vertical align; whitespace; word break; hyphens; content
    **/
    --font-family-base: Arial, sans-serif;

    --font-size-base: 1rem; /* 16px */
    --font-size-xxs: 0.688rem; /* 11px */
    --font-size-xs: 0.75rem; /* 12px */
    --font-size-sm: 0.875rem; /* 14px */
    --font-size-lg: 1.125rem; /* 18px */
    --font-size-xl: 1.25rem; /* 20px */
    --font-size-2xl: 1.5rem; /* 24px */
    --font-size-3xl: 1.875rem; /* 30px */
    --font-size-4xl: 2.25rem; /* 36px */

    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-bolder: 900;

    --line-height-base: 24px;
    --line-height-xxs: 15px;
    --line-height-xs: 16px;
    --line-height-sm: 20px;
    --line-height-lg: 24px;
    --line-height-xl: 28px;
    --line-height-2xl: 32px;
    --line-height-3xl: 36px;
    --line-height-4xl: 40px;
    --line-height-5xl: 1em;

    --letter-spacing-tighter: -0.8px;
    --letter-spacing-tight: -0.25px;
    --letter-spacing-normal: 0em;
    --letter-spacing-wide: 0.25px;
    --letter-spacing-wider: 0.8px;
    --letter-spacing-widest: 1.6px;

    /** 
        Spacing - https://tailwindcss.com/docs/padding
        examples: padding; margin; space between
    **/
    --padding-2: 0.125rem; /* 2px */
    --padding-4: 0.25rem; /* 4px */
    --padding-8: 0.5rem; /* 8px */
    --padding-12: 0.75rem; /* 12px */
    --padding-16: 1rem; /* 16px */
    --padding-20: 1.25rem; /* 20px */
    --padding-22: 1.375rem; /* 20px */
    --padding-24: 1.5rem; /* 24px */
    --padding-28: 1.75rem; /* 28px */
    --padding-32: 2rem; /* 32px */
    --padding-36: 2.25rem; /* 36px */
    --padding-40: 2.5rem; /* 40px */
    --padding-44: 2.75rem; /* 44px */
    --padding-48: 3rem; /* 48px */
    --padding-52: 3.25rem; /* 52px */
}

/** Breakpoints **/
$breakpoint-sm: '640px';
$breakpoint-md: '768px';
$breakpoint-lg: '1024px';
$breakpoint-xl: '1280px';
